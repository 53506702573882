.ShipDial {
    width: 140px;
    margin: auto;
}

.dialSpeed {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
