/* .fleets {
    display: flex;
    flex-wrap: wrap;
    color: white;
    font-family: StarcraftNormal;
}
.fleet .shipCard {
    width: 200px;
    height: 278px;
}
.fleet .shipCard img {
    max-width: 100%;
}
.fleet .title {
    margin-left: 0;
}*/
.fleets a {
    color: white;
    text-decoration: none;
} 
input {
    background-color: transparent;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}
.cardContainer {color: white;}
.shipCardsInFleet .ship_upg {
    cursor: pointer;
}
input:focus-visible {
    outline: 1px solid red ;
    outline-offset: 1px;
}