.fleets {
  display: flex;
  flex-wrap: wrap;
  color: white;
  font-family: StarcraftNormal;
}
.fleet .cardList .Cover ,
    .fleet.selectCard .Cover {
  width: 200px;
  height: 278px;
}
.fleet .shipCard img {
  max-width: 100%;
}
.fleet .title {
  margin-left: 0;
}
.fleet .ShipContainer:has(.cardContainer) .ships {
  height: auto;
}
.fleet .cardContainer {
  width: calc(100vw - 458px);
}
.fleet .cardContainer .shipCard {
  position: relative;
}
.deleteCard {
    position: absolute;
    top: -25px;
    right: -21px;
    color: red;
    font-size: 22px;
    border: 1px solid red;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    background-color: black;
}
.upg_token .deleteCard {
  top: 6px;
  right: 14px;
}
.shipCardsInFleet {
  position: relative;
}
.shipCardsInFleet .upg_token {
  position: absolute;
  top: 4px;
}