@font-face {
  font-family: "StarcraftNormal";
  src: local("StarcraftNormal"),
   url("./fonts/StarcraftNormal.ttf") format("truetype");
  font-weight: normal;
 }
 
 @font-face {
  font-family: "SourceSans3";
  src: local("SourceSans3"),
   url("./fonts/SourceSans3-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
 }
 @font-face {
  font-family: "SourceSans3Italic";
  src: local("SourceSans3Italic"),
   url("./fonts/SourceSans3-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
 }

 .App-old {
  font-family: "SourceSans3";
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
  "header header header header"
  "side content content content"
  "footer footer footer footer";
}
.App {
  font-family: "SourceSans3";
  display: grid;
  grid-template-areas:
  "header"
  "content"
  "footer";
  background-color: black;
}

@media only screen and (max-width: 600px) {
  .App-old {
    grid-template-areas:
    "header header header"
    "side side side"
    "content content content"
    "footer  footer  footer";
  }
  .App {
    grid-template-areas:
    "header"
    "content"
    "footer";
  }
}
.App-header a.active {
  text-shadow: 0 0 10px #fff;
}
.App-header a{
  transition-duration: .5s;
  text-decoration: none;
  color: white;
}
.App-header {
  grid-area: header;
  height: 100px;
  font-family: StarcraftNormal;
  text-align: center;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 30px;
  grid-gap: 20px;
  align-items: center;
}

.App-side {
  grid-area: side;
}

.App-content {
  grid-area: content;
  height: calc(100vh - 200px);
  overflow: auto;
}

.App-footer {
  grid-area: footer;
  height: 100px;

}

.App-menu {

}

.App-tabulator {
  display: flex;
  justify-content: flex-start;
}

.App-tabulator div {
  margin: 10px;
}