.bases {
  display: flex;
  flex-wrap: wrap;
}
.bases .shipBase,
.bases .BaseDial {
  margin: 10px;
  overflow: hidden;
}
.BaseDial.Cover .racePic {
  width: 40%;
  margin: 15% 8% 20%;
}
.BaseDial.Cover .name {
  text-align: center;
  color: white;
  font-family: "StarcraftNormal";
}
.shipBase {
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
  transform: scale(0.9, 1);
}
.bases .small::after {
  transform: scale(1.1, 1);
  content: " ";
  position: absolute;
  background-color: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
}
.bases .medium::after,
.bases .large::after
/*.bases .huge::after */ {
  transform: scale(1.1, 1);
  content: " ";
  position: absolute;
  background-color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  top: calc(50% - 22.5px);
  left: calc(50% - 22.5px);
}
.small {
  width: 120px;
  height: 120px;
}
.small .bullArc {
  top: -68%;
}
.medium .bullArc,
.medium .attackBull {
  top: -62%;
}
.large .bullArc {
  top: -59%;
}
.huge .bullArc {
  top: -53%;
}
.medium {
  width: 180px;
  height: 180px;
}
.large {
  width: 240px;
  height: 240px;
}
.huge {
  width: 264px;
  height: 672px;
}
.crossVertical,
.crossHorisontal {
  color: white;
  position: absolute;
  top: 50%;
  width: 100%;
  opacity: 1;
  border-style: dashed;
}
.crossVertical {
  rotate: 90deg;
}
.huge .crossVertical {
  width: 672px;
  right: -205px;
}

.shipBase.zerg .attackLeft,
.shipBase.zerg .attack,
.shipBase.zerg .attackRight,
.shipBase.zerg .attack45 {
  background-color: rgba(128, 0, 128, 0.5);
  border: 1px solid #800080;
}
.shipBase.zerg .attackBull {
  border-left: 1px solid #800080;
  border-right: 1px solid #800080;
}
.shipBase.terran .attackLeft,
.shipBase.terran .attack,
.shipBase.terran .attackRight,
.shipBase.terran .attackBull,
.shipBase.terran .attack45 {
  background-color: rgba(0, 0, 255, 0.5);
  border: 1px solid #0000ff;
}
.shipBase.protoss .attackLeft,
.shipBase.protoss .attack,
.shipBase.protoss .attackRight,
.shipBase.protoss .attackBull,
.shipBase.protoss .attack45 {
  background-color: rgba(255, 255, 0, 0.5);
  border: 1px solid #ffff00;
}

.shipBase .attackLeft,
.shipBase .attack,
.shipBase .attackRight,
.shipBase .attack45 {
  width: 100%;
  height: 100%;
  position: absolute;
  rotate: 45deg;
  top: -70%;
}
.huge .attack {
  top: -406px;
  left: -39px;
  width: 480px;
}
.huge .attackLeft {
  top: 145px;
  left: -329px;
}
.huge .attackRight {
  top: -144px;
  right: -331px;
}
.shipBase .attack45Right,
.shipBase .attack45Left {
  opacity: 1;
  color: #df1415;
}
.shipBase .attack45Right {
  rotate: -67.5deg;
  top: 161px;
  position: absolute;
  left: 16px;
  width: 380px;
}
.shipBase .attack45Left {
  rotate: 67.5deg;
  top: 161px;
  position: absolute;
  left: -131px;
  width: 380px;
}
.shipBase .baseName {
  font-family: "StarcraftNormal";
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.shipBase .backArc,
.shipBase .frontArc {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  rotate: 45deg;
  top: 70%;
}
.shipBase .frontArc {
  top: -70%;
}
.shipBase .bullArc,
.shipBase .attackBull {
  rotate: 0deg;
  border: 1px solid white;
  border-bottom: 0px;
  position: absolute;
  height: 100%;
  width: 45px;
  left: calc(50% - 22.5px);
}
.huge .frontArc {
  top: -407px;
  left: -39px;
  width: 480px;
}
.huge .backArc {
  top: 408px;
  left: -175px;
  width: 480px;
}
.BaseDial {
  width: 250px;
  height: 250px;
  position: relative;
  background-color: black;
  border-radius: 50%;
}
.BaseDial.Cover {
  width: 280px;
  height: 280px;
}
.BaseDial .dialManeuver {
  height: calc(100% - 20px);
  width: 25px;
  position: absolute;
  top: 10px;
  left: calc(50% - 12.5px);
  text-align: center;
  font-family: "StarcraftNormal";
  color: white;
}
.BaseDial::after {
  content: " ";
  position: absolute;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}
.bases .shipIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% + 20px);
  left: calc(50% - 20px);
}
