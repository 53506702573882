.shipAction {
    display: flex;
}

.shipAction .shipActionIcon {
    width: 30px;
    height: 30px;
}

.shipAction .shipActionIcon:nth-of-type(2) {
    margin-left: 3px;
}