.cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  transition-duration: 0.5s;
  width: 100%;
  height: calc(100vh - 200px);
}
.ShipContainer:has(.ships) .cardList {
  flex-direction: column;
  width: 110px;
}
.ShipContainer:has(.ships) .cardList .shipCard {
  width: 100px;
  height: 140px;
}
.selected.selectCard .shipCard {
  display: flex;
  box-shadow: 0px 0px 12px white;
}
.selected.selectCard .shipCard.protoss,
.selectCard:hover .shipCard.protoss,
.ships .shipCard.protoss:hover {
  box-shadow: 0px 0px 12px #ffff00;
}
.selected.selectCard .shipCard.terran,
.selectCard:hover .shipCard.terran,
.ships .shipCard.terran:hover  {
  box-shadow: 0px 0px 12px #0000ff;
}
.selected.selectCard .shipCard.zerg,
.selectCard:hover .shipCard.zerg,
.ships .shipCard.zerg:hover  {
  box-shadow: 0px 0px 12px #800080;
}
.selectCard {
  font-family: StarcraftNormal;
  text-align: center;
  cursor: pointer;
  color: white;
}
.selectCard .shipCard {
  transition-duration: 0.5s;
  border: solid 1px white;
}
.ShipContainer:has(.ships) {
  display: grid;
  grid-template-areas: "cards ships";
  grid-template-columns: 110px auto;
  grid-gap: 10px;
  height: calc(100vh - 200px);
}
.ShipContainer:has(.cardContainer) .ships {
  width: 328px;
}
.ShipContainer:has(.cardContainer) {
  display: grid;
  grid-template-areas: "cards ships ship";
  grid-template-columns: 110px 328px auto;
  grid-gap: 10px;
  height: calc(100vh - 528px);
}
.cardList {
  grid-area: cards;
}
.ships {
  grid-area: ships;
  height: calc(100vh - 200px);
  overflow: overlay;
  width: calc(100vw - 120px);
}
.ships .shipCard {
  transform: scale(0.75);
  display: inline-block;
  border: 1px solid white;
  height: 166px;
  cursor: pointer;
}

.ships .terran.shipCard,
.cardContainer .terran.shipCard,
.cardContainer .terran.upgradeCard,
.ships .shipCard:hover,
.terran input {
  border: 2px solid #0000ff;
}
.ships .protoss.shipCard,
.cardContainer .protoss.shipCard,
.cardContainer .protoss.upgradeCard,
.protoss input {
  border: 2px solid #ffff00;
}
.ships .zerg.shipCard,
.cardContainer .zerg.shipCard,
.cardContainer .zerg.upgradeCard,
.zerg input  {
  border: 2px solid #800080;
}
.cardContainer .shipCard,
.cardContainer .upgradeCard {
    transform: scale(.85);
}
.cardContainer{
    height: 100%;
    overflow: auto;
}
.title {
    font-family: StarcraftNormal;
    margin-left: 30px;
    color: white;
    font-size: 20px;
}
.cardContainer .shipBase ,
.cardContainer .BaseDial {
    border: 2px solid #6a6a73;
}
.selectedShip {
    display: flex;
    flex-wrap: wrap;
}
.selectCard a {
  color: white;
  text-decoration: none;
}