
.token {
    margin: 5px;
    background-color: black;
}
.token img {
    width: -webkit-fill-available;
}
.token.small {
    width: 70px;
    height: 70px;
}
.terran .token.small  {
  border: 2px solid #0000ff;}
.protoss .token.small  {
  border: 2px solid #ffff00;}
.zerg .token.small  {
  border: 2px solid #800080;}
.upg_token {
    display: flex;
}