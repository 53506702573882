.shipPoint {
    text-align: center;
    font-family: "StarcraftNormal";
}

.shipPoint .icon {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 0px solid;
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.shipPoint.attack .icon .iconInner,
.shipPoint.attackLeft .icon .iconInner,
.shipPoint.attackRight .icon .iconInner,
.shipPoint.attack45 .icon .iconInner {
    border-radius: 50%;
    width: 0; 
    height: 0;
    margin-left: auto;
    margin-right: auto;
}
.shipPoint.attack45 .icon .iconInner{
margin-top: 10px;
border-left: 11.5px solid transparent;
border-right: 11.5px solid transparent;
border-top: 25.5px solid #df1415;
}
.shipPoint.attack .icon .iconInner {
    margin-top: 10px;
    border-left: 21.5px solid transparent;
    border-right: 21.5px solid transparent;
    border-top: 25.5px solid #df1415;
}
.attackLeft .icon .iconInner {
    margin-top: 1px;
    border-bottom: 21.5px solid transparent;
    border-top: 21.5px solid transparent;
    border-left: 25.5px solid #df1415;
}
.attackRight .icon .iconInner {
    margin-top: 1px;
    border-bottom: 21.5px solid transparent;
    border-top: 21.5px solid transparent;
    border-right: 25.5px solid #df1415;
}
.attackBull .icon .iconInner {
    background-color: #df1415;
    width: 10px;
    height: 35px;
    margin: auto;
    margin-top: 5px;
}
.attack, .attackLeft, .attackRight, .attackBull, .attack45 {
    color: #df1415;
}

.agility {
    color: #6eb437;
}
.agility .icon .iconInner {
    width: 16px;
    height: 16px;
    margin: 14px 8px;
    background-color: #6eb437;
    position: relative;

}
.agility .icon .iconInner::after {
    margin-top: -6px;
    border-bottom: 14.5px solid transparent;
    border-top: 14.5px solid transparent;
    border-left: 20.5px solid #6eb437;
    content: ' ';
    position: absolute;
    left: 9px;
}

.health {
    color: #fae916;
}
.health .icon .iconInner {
    width: 16px;
    height: 32px;
    margin: 8px auto;
    background-color: #fae916;
    position: relative;
}
.health .icon .iconInner::after {
    width: 32px;
    height: 16px;
    margin: 8px auto;
    background-color: #fae916;
    content: ' ';
    position: absolute;
    left: -8px;
}
.energy {
    color: #de167e;
}
.shipPoint .amount {
    position: relative;
}

.energy .icon .iconInner {
    position: relative;
    margin-top: 9px;
    margin-left: 14px;
    border-bottom: 14.5px solid transparent;
    border-top: 5.5px solid transparent;
    border-left: 11.5px solid #de167e;
    rotate: 23deg;
}

.energy .icon .iconInner::after {
    margin-top: 7px;
    border-bottom: 14.5px solid transparent;
    border-top: 5.5px solid transparent;
    border-left: 11.5px solid #de167e;
    content: ' ';
    position: absolute;
    left: -1px;
}
.energy .icon .iconInner::before {
    margin-top: -2px;
    content: ' ';
    position: absolute;
    left: -9px;
    border-bottom: 14.5px solid transparent;
    border-top: 5.5px solid transparent;
    border-left: 11.5px solid #de167e;
    rotate: -104deg;
}

.up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    left: 30px;
    top: 0px;
}
.energy .up {
    border-bottom: 8px solid #de167e;
}
.health .up {
    border-bottom: 8px solid #fae916;
}

.down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    left: 30px;
    top: 0px;
}
.energy .down {
    border-top: 8px solid #de167e;
}
.shipPoint.shield .icon .iconInner {
    background-color: #7ed0e1;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin: 5px auto;
}
.shield {
    color: #7ed0e1;
}
.interceptor {
    color: #fae916;
}

.interceptor img {
    width: 40px;
    height: 40px;
    margin-top: 2px;
}