.upgradeCard {
    width: 419px;
    height: 301px;
    border-radius: 13px;
    background-color: #080808;
    color: white;
    overflow: hidden;
    display: grid;
    grid-template-areas:"upgradeSide upgradeMain";
    align-items: stretch;
    grid-template-columns: 110px auto;
    text-align: center;
    margin: 5px;
}
.upgradeSide {
    grid-area: upgradeSide;
    border-right: 2px solid #6A6A73;
    background-color: black;
    margin: 0px 5px;
}
.terran .upgradeSide {
  border-right: 2px solid #0000ff;}
.protoss .upgradeSide {
  border-right: 2px solid #ffff00;}
.zerg .upgradeSide {
  border-right: 2px solid #800080;}
.parentPic {
    width: 100%;
    padding: 20px;
}
.parentName {
    font-family: "StarcraftNormal";
    font-size: 12px;
    word-break: break-word;
}
.upgradeMain {
    grid-area: upgradeMain;
    width: 100%;
    height: 299px;
    display: grid;
    grid-template-areas:"upgradePic upgradePlusses"
    "upgradeName upgradeName"
    "upgradeDesc upgradeDesc"
    "upgradeFooter upgradeFooter";
    align-items: stretch;
    grid-template-columns: 150px auto;
    grid-template-rows: 100px auto auto;
}
.upgradePic {
    grid-area: upgradePic;
}
.upgradePic img {
    margin: auto;
    display: block;
    height: 100%;
    padding: 5px;

}
.upgradePlusses {
    grid-area: upgradePlusses;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}
.upgradeName {
    grid-area: upgradeName;
    font-family: "StarcraftNormal";
    margin: 10px 0px;
    border-top: 2px solid #6A6A73;
    border-bottom: 2px solid #6A6A73;
}
.terran .upgradeName {
    border-top: 2px solid #0000ff;
    border-bottom: 2px solid #0000ff;}
.protoss .upgradeName {
    border-top: 2px solid #ffff00;
    border-bottom: 2px solid #ffff00;}
.zerg .upgradeName {
    border-top: 2px solid #800080;
    border-bottom: 2px solid #800080;}
.upgradeDesc {
    grid-area: upgradeDesc;
    font-size: 10px;
    margin: 10px;
    margin-top: 0;
    height: 103px;
    white-space: pre-wrap;
}
.upgradeAbility {
    margin: 10px;
    white-space: pre-wrap;
}
.upgradeFooter {
    grid-area: upgradeFooter;
    width: 100%;
    height: 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid #6A6A73;
}
.terran .upgradeFooter {
  border-top: 2px solid #0000ff;}
.protoss .upgradeFooter {
  border-top: 2px solid #ffff00;}
.zerg .upgradeFooter {
  border-top: 2px solid #800080;}
.upgradeCost {
    display: flex;
    padding: 4px 8px;
}
.upgradeExtras {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4px;
    margin: auto;
    height: 31px;
}
.upgradeCard.Cover {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Cover img {
    max-height: 90%;
}
img.psionicStorm {
    height: fit-content;
}
img.disruptionWeb {
    height: fit-content;
    border-radius: 50%;
}