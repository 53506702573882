.shipCards {
  display: flex;
  flex-wrap: wrap;
}
.testPic {
  position: absolute;
  z-index: -1;
}
.shipCard {
  height: 419px;
  width: 301px;
  border-radius: 13px;
  background-color: #080808;
  color: white;
  margin: 5px;
}
.shipHead {
  border-radius: 13px 13px 0 0;
  width: 100%;
  height: 127px;
  position: relative;
  background-color: black;
}
.protoss .shipHead {
  background-color: #050808;
}
.shipPic {
  height: 100%;
  margin: auto;
  display: block;
  padding: 5px;
}
.shipType {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
}
.shipType .shipTypeIcon {
  width: 25px;
}
.shipName {
  grid-area: shipName;
  text-align: center;
  font-family: "StarcraftNormal";
  border-top: 2px solid #6a6a73;
  border-bottom: 2px solid #6a6a73;
}
.terran .shipName {
  border-top: 2px solid #0000ff;
  border-bottom: 2px solid #0000ff;
}
.protoss .shipName {
  border-top: 2px solid #FFFF00;
  border-bottom: 2px solid #FFFF00;
}
.zerg .shipName {
  border-top: 2px solid #800080;
  border-bottom: 2px solid #800080;
}
.shipMain.shipMainCard {
  grid-template-areas:
    "shipName"
    "shipDesc"
    "shipDesc"
    "shipPoints";
  align-items: stretch;
  grid-template-rows: 25px auto auto 70px;
  grid-template-columns: auto;
}
.shipMain {
  width: 100%;
  height: 260px;
  display: grid;
  grid-gap: 0px;
  grid-template-areas:
    "shipName shipName shipName"
    "shipDesc shipDesc shipActions"
    "shipDesc shipDesc shipActions"
    "shipPoints shipPoints shipActions";
  align-items: stretch;
  grid-template-rows: 25px auto auto 70px;
  grid-template-columns: auto auto 70px;
}
.shipMain.shipMainCard .shipDesc {
  display: flex;
  align-items: center;
}
.shipDesc {
  grid-area: shipDesc;
  font-size: 10px;
  margin: 10px;
  text-align: center;
  white-space: pre-wrap;
}
.shipAbility {
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  white-space: pre-wrap;
}
.shipPoints {
  grid-area: shipPoints;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.shipActions {
  grid-area: shipActions;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-left: 2px solid #6a6a73;
  background: black;
}
.terran .shipActions {
  border-left: 2px solid #0000ff;}
.protoss .shipActions {
  border-left: 2px solid #ffff00;}
.zerg .shipActions {
  border-left: 2px solid #800080;}
.actionsNum {
  text-align: center;
  width: 40px;
  height: 40px;
  padding: 5px;
}
.shipFooter {
  border-radius: 0 0 13px 13px;
  width: 100%;
  height: 31px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 2px solid #6a6a73;
}
.terran .shipFooter {
  border-top: 2px solid #0000ff;}
.protoss .shipFooter {
  border-top: 2px solid #ffff00;}
.zerg .shipFooter {
  border-top: 2px solid #800080;}
.shipExtras {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 4px;
}
.shipIcon {
  display: flex;
  border-radius: 0 0 0 13px;
  overflow: hidden;
}
.shipDetector {
  display: flex;
}
.shipTransport {
  display: flex;
}
.shipCommand {
  display: flex;
}
.shipCost {
  display: flex;
  padding: 4px 8px;
}
hr {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.shipDetector .shipDetectorIcon,
.shipTransport .shipTransport,
.shipCommand img,
.cloakRange img {
  height: 21px;
}
.unitSize img {
  height: 20px;
}
.ship_upgs {
  display: flex;
}
.ship_upg {
  width: 50px;
  height: 50px;
}
.ship_upg img {
  width: 100%;
}
.shipCard.Cover {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
img.darkSwarm {
  height: fit-content;
  border-radius: 38%;
}
.shipCard.random div {
  font-size: 150px;
  text-align: center;
  margin-left: 50px;
}